import React from "react";
import { Stack, Flex } from "@chakra-ui/react";
import Header from "../../Components/PressureDetailsComponents/Header";
import Form from "../../Components/PressureDetailsComponents/Form";

function PressureDetails({ data }) {

  return (
    <Stack p={[0, 1, 3, 5]} w={["95%", "90%", "80%"]} h={"100%"} overflow={"scroll"} backgroundColor={"white"}>
      <Flex
        m={[2, 3, 4]}
        mt={20}
        justifyContent={"center"}
        borderBottom={"2px"}
        borderBottomColor={"black"}
      >
        <Header data={data} />
      </Flex>
      <Flex
        justifyContent={"center"}
        mt={5}
        p={0}
      >
      </Flex>  
      <Flex mt={20} justifyContent={"center"}>
        <Form data={data} />
      </Flex>
    </Stack>
  );
}

export default PressureDetails;
