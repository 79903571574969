import {Stack, Flex, Heading, Button} from '@chakra-ui/react'
import { useParams } from 'react-router-dom';

function Header(){
    const params = useParams();

    return (
        <Stack
            position={'fixed'}
            top={0}
            bg={'white'}
            minW={'100vw'}
            alignItems={'center'}
            p={2}
            pt={4}
        >
            <Flex>
                <Heading>
                    Pressure
                </Heading>
                {params.pressureId ?
                    <Button 
                        onClick={()=>{
                            var now = new Date().valueOf();
                            setTimeout(function () {
                                if (new Date().valueOf() - now > 100) return;
                                window.location = `../appRedirect/${params.pressureId}`;
                            }, 25);
                            window.location = `api.pressures.app://p/${params.pressureId}`;
                        }}
                        position="absolute" right="5">
                        Open App
                    </Button> 
                : null}
            </Flex>
        </Stack>
    )
}

export default Header